<template>
  <v-navigation-drawer
    v-model="Sidebar_drawer"
    :dark="SidebarColor !== 'white'"
    :color="SidebarColor"
    mobile-breakpoint="960"
    clipped
    :right="$vuetify.rtl"
    mini-variant-width="70"
    :expand-on-hover="expandOnHover"
    app
    id="main-sidebar"
  >
    <v-list dense nav>
      <!---USer Area -->
      <v-list-item two-line class="px-0">
        <v-list-item-avatar>
          <v-avatar color="indigo">
            <span class="white--text capitalize avatar-span" v-if="userDetail.fullName">{{ (userDetail.fullName).charAt(0) }}</span>
          </v-avatar>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="capitalize">{{ userDetail.fullName }}</v-list-item-title>
          <v-list-item-subtitle class="caption capitalize">APPS ADMIN</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <!---USer Area -->
      <!---Sidebar Items -->
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="item.to"
        :active-class="`primary white--text`"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!---Sidebar Items -->

      <v-list-group
        v-for="item in groupItems"
        :key="item.title"
        v-model="item.active"
        :prepend-icon="item.action"
        no-action
        append-icon=""
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in item.items"
          :key="child.title"
          :to="child.to"
          :active-class="`primary white--text`"
          link
        >
          <v-list-item-content>
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "Sidebar",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    items: [
      {
        title: "PI ADs",
        icon: "mdi-apps",
        to: "/pi-ads"
      },
      {
        title: "Google ADs",
        icon: "mdi-google",
        to: "/google-ads"
      },
      {
        title: "Applications",
        icon: "mdi-google-play",
        to: "/apps"
      },
    ],
    groupItems:[
      {
        action: 'mdi-brush-variant',
        items: [
          { title: 'Add Event', icon: "mdi-calendar", to: "/banner-master/event" },
          { title: 'Add Upcoming Events', icon: "mdi-calendar-search", to: "/banner-master/upcoming-event" },
          { title: 'Upload Banners', icon: "mdi-image-area", to: "/banner-master/banner" },
          { title: 'Home Page Sliders', icon: "fa fa-sliders", to: "/banner-master/home-page-sliders" },
          { title: 'Frame play ground', icon: "fa fa-sliders", to: "/banner-master/frame-tester" },
          { title: 'Custom Upload', icon: "fa fa-sliders", to: "/banner-master/custom-upload" }
        ],
        title: 'iBanner',
      },
    ]
  }),
  mounted: function () {
    this.getLoginUserDetail();
  },
  created: function () {},
  computed: {
    ...mapState(["SidebarColor", "SidebarBg"]),
    Sidebar_drawer: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      }
    }
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    }
  },

  methods: {
    getLoginUserDetail(){
      axios.get(this.Node_JS_HOST + "/api/v1/web/auth/")
        .then((res) => {
          this.userDetail = res.data.data;
        })
    }
  }
};
</script>
<style lang="scss">
#main-sidebar{
  box-shadow:1px 0 20px rgba(0,0,0,.08);
  -webkit-box-shadow:1px 0 20px rgba(0,0,0,.08);
  .v-navigation-drawer__border{
    display: none;
  }
  .v-list{
    padding: 8px 15px;
  }
  .v-list-item{
      &__icon--text,
      &__icon:first-child{
        justify-content: center;
        text-align: center;
        width: 20px;
        
      }
      
  }
  .capitalize{
    text-transform: capitalize;
  }
  .avatar-span{
    font-weight: 600;
    font-size: 20px;
  }
}
</style>